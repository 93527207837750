import React from 'react';
import "../styles/InviteHeader.css";
import headerimageleft from "../assets/invite-header-left.png";
import headerimageright from "../assets/invite-header-right.png";
 
const InviteHeader = () => (
    <header className="invite-header" >
        <img src={headerimageleft} className="invite-header-left" alt="header-left" />
        <img src={headerimageright} className="invite-header-right" alt="header-right" />
    </header>
);
 
export default InviteHeader;