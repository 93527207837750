import { Link } from "react-router-dom";
import "../styles/menu.css"

function MannaOnlineMenu() {

  return (
     <div className="MannaOnlineMenu"> 
      <header className="MannaOnlineMenu-header">
        MannaOnline
      </header>

      <nav className="MannaOnlineMenu-links">
      <ul>
        <li><Link to="/invite">Invite Page</Link></li>
        <li><Link to="/RSVPList">RSVP List</Link></li>
        <li><Link to="/StockList">Stock List</Link></li>
        <li><Link to="/Arbs">Arbs</Link></li>
      </ul>
      </nav>

    </div> 
  );
}

export default MannaOnlineMenu;
