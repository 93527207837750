import './App.css';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

// import components
import MannaOnline from './pages/home';
import MannaOnlineMenu from './pages/menu';
import Invite from './pages/invite';
import RSVPList from './pages/RSVPList';
import StockList from './pages/StockList';
import Arbs from './pages/Arbs'

function App() {
  return (
    <>
       <Router>
        <Routes>
          <Route exact path="/" element={<MannaOnline />} />
          <Route exact path="/menu" element={<MannaOnlineMenu />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/RSVPList" element={<RSVPList />} />
          <Route path="/StockList" element={<StockList />} />
          <Route path="/Arbs" element={<Arbs />} />
        </Routes>
        {/* <Navigate to="/" /> */}
      </Router>
  </>
  );
}

export default App;
