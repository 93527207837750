import React, { useState, useEffect } from 'react';
import axios from 'axios';

const api_key = process.env.REACT_APP_ODDS_API_KEY_PERSONAL;
const availableSports = [];

export async function sports() {
  try {
    const response = await axios.get(`https://api.the-odds-api.com/v4/sports/`, {
      params: { apiKey: api_key }
    });

    const data = response.data;
    for (const sport of data) {
      availableSports.push(sport["key"]);
    }

    return availableSports;
  } catch (error) {
    console.error('Error fetching sports:', error);
    throw error; // Re-throw the error to be caught by the caller
  }
}
