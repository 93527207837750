
import React, { useState } from 'react'
import { Axios, db } from '../firebase/firebaseConfig'
import '../styles/RSVP.scss'

const RSVPform = () => {
  const [formData, setFormData] = useState({})

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = event => {
    event.preventDefault()
    sendEmail()
    setFormData({
      Name: '',
      NumPeopl: '',
    })
  }
  const sendEmail = () => {

    Axios.post(
      'https://us-central1-mannaonlineinvite.cloudfunctions.net/submit',
      formData
    )
      .then(res => {
        db.collection('RSVP').add({
          Name: formData.Name,
          NumPeopl: formData.NumPeopl,
          time: formData.time,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <>
      <form id="RSVP-form-data" onSubmit={handleSubmit}>
        <input
          id='RSVP-form-data-name'
          type="text"
          name="Name"
          placeholder="Name"
          onChange={updateInput}
          value={formData.Name || ''}
        />
        <input
          id='RSVP-form-data-NumPeopl'
          type="text"
          name="NumPeopl"
          placeholder="How many are coming along?"
          onChange={updateInput}
          value={formData.NumPeopl || ''}
        />
        <button id='submit' type="submit">RSVP</button>
      </form>
    </>
  )
}

export default RSVPform