import React from 'react';
import "../styles/Stocklist.css"


function StockList() {
      return (
        <div className='Stocklist'>
        <header className="StockList-header"> Amibroker Results </header>

        <div className="StockList-iframe-container">
          <iframe
            title="OneDrive Embed"
            src="https://onedrive.live.com/embed?resid=A3711F6D121E29DC%212170993&authkey=!APSdwLGBUAjxxfY&em=2"
          ></iframe>
        </div>
        </div>
      );
}

  export default StockList;
