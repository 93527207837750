import { Link } from "react-router-dom";
import "../styles/home.css"

function MannaOnline() {

  return (
     <div className="App">
      <header className="App-header">
       <Link to="/menu">MannaOnline</Link>
      </header>
    </div> 
  );
}

export default MannaOnline;
