import React, { useEffect, useState } from 'react';
import '../firebase/firebaseConfig';
import firebase from 'firebase/app';
import 'firebase/database';
import '../styles/RSVPList.css'

function RSVPList() {
  const [formData, setFormData] = useState([]); // Initialize as an empty array

  useEffect(() => {
    // Reference to the 'RSVP' node in the Realtime Database
    const ref = firebase.database().ref('RSVP');

    // Fetch data from the Realtime Database
    ref.on('value', (snapshot) => {
      const dataArr = [];
      snapshot.forEach((childSnapshot) => {
        dataArr.push(childSnapshot.val());
      });
      setFormData(dataArr);
    });

    // Clean up the listener on unmount
    return () => ref.off('value');
  }, []);

  return (
    <div className='rsvp-list'>
      <h1>RSVP List</h1>
      <div className='rsvp-table'>
      <table>
        <thead>
          <tr>
            <th>Time</th>
            <th>Name</th>
            <th>Number of People</th>
          </tr>
        </thead>
        <tbody>
          {formData.map((item, index) => (
            <tr key={index}>
              <td>{item.time}</td>
              <td>{item.name}</td>
              <td>{item.NumPeopl}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default RSVPList;
