import axios from 'axios';
const regions = 'au';
const markets = 'h2h';
const oddsFormat = 'decimal';
const dateFormat = 'iso';
const currentISODate = new Date().toISOString();


let api_key; 

if (currentISODate % 2 === 0) {
    api_key = process.env.REACT_APP_ODDS_API_KEY_PERSONAL; //process.env.REACT_APP_ODDS_API_KEY_PERSONAL // REACT_APP_ODDS_API_KEY_MANNA //backup key
} else {
    api_key = process.env.REACT_APP_ODDS_API_KEY_MANNA;
};

function computeSurebet(odds_a, odds_b) {
    const implied_prob_a = 1 / odds_a;
    const implied_prob_b = 1 / odds_b;
    const total_implied_prob = implied_prob_a + implied_prob_b;
    return [total_implied_prob];
}

const surebets = [];

export default function findArbs(sportKeys) {
    const requests = sportKeys.map(sportKey => {
        return axios.get(`https://api.the-odds-api.com/v4/sports/${sportKey}/odds`, {
            params: {
                apiKey: api_key,
                regions: regions,
                markets: markets,
                oddsFormat: oddsFormat,
                dateFormat: dateFormat,
                commence_time: currentISODate,
            }
        })
        .then(response => {
            console.log(`Remaining requests for ${sportKey}:`, response.headers['x-requests-remaining']);
            console.log(`Used requests for ${sportKey}:`, response.headers['x-requests-used']);

            const data = response.data;

        for (const event of data) {
            const team_a = event["home_team"];
            const team_b = event["away_team"];

            const odds_list = event["bookmakers"].map(bookmaker => {
                const bookmaker_name = bookmaker["title"];
                const odds_a = bookmaker["markets"][0]["outcomes"][0]["price"];
                const odds_b = bookmaker["markets"][0]["outcomes"][1]["price"];
                return { bookmaker_name, odds_a, odds_b };
            });

            for (let i = 0; i < odds_list.length; i++) {
                for (let j = i + 1; j < odds_list.length; j++) {
                    const bookmaker_a = odds_list[i].bookmaker_name;
                    const bookmaker_b = odds_list[j].bookmaker_name;
                    const odds_a_a = odds_list[i].odds_a;
                    const odds_b_a = odds_list[i].odds_b;
                    const odds_a_b = odds_list[j].odds_a;
                    const odds_b_b = odds_list[j].odds_b;

                    const [profit_a] = computeSurebet(odds_a_a, odds_b_b);
                    const [profit_b] = computeSurebet(odds_a_b, odds_b_a);

                    if (profit_a < 1.01) {
                        if (odds_a_a < odds_b_b) {
                                surebets.push({
                                    "Start Time": event["commence_time"],
                                    "Sport": sportKey,
                                    "Event": `${team_a} v. ${team_b}`,
                                    "Team A": team_b,
                                    "Bookmaker A": bookmaker_b,
                                    "Odds A (Bookmaker A)": odds_b_b,
                                    "Team B": team_a,
                                    "Bookmaker B": bookmaker_a,
                                    "Odds B (Bookmaker B)": odds_a_a,
                                    "Combined Odds": profit_a.toFixed(3)*100
                                });
                            } else {
                                surebets.push({
                                    "Start Time": event["commence_time"],
                                    "Sport": sportKey,
                                    "Event": `${team_a} v. ${team_b}`,
                                    "Team A": team_a,
                                    "Bookmaker A": bookmaker_a,
                                    "Odds A (Bookmaker A)": odds_a_a,
                                    "Team B": team_b,
                                    "Bookmaker B": bookmaker_b,
                                    "Odds B (Bookmaker B)": odds_b_b,
                                    "Combined Odds": profit_a.toFixed(3)*100
                            });
                    }
                }

                    if (profit_b < 1.01) {
                        if (odds_a_a < odds_b_b) {
                            surebets.push({
                                "Start Time": event["commence_time"],
                                "Sport": sportKey,
                                "Event": `${team_a} v. ${team_b}`,
                                "Team A": team_b,
                                "Bookmaker A": bookmaker_b,
                                "Odds A (Bookmaker A)": odds_b_a,
                                "Team B": team_a,
                                "Bookmaker B": bookmaker_a,
                                "Odds B (Bookmaker B)": odds_a_b,
                                "Combined Odds": profit_b.toFixed(3)*100
                            });
                        } else {
                            surebets.push({
                                "Start Time": event["commence_time"],
                                "Sport": sportKey,
                                "Event": `${team_a} v. ${team_b}`,
                                "Team A": team_a,
                                "Bookmaker A": bookmaker_a,
                                "Odds A (Bookmaker A)": odds_a_b,
                                "Team B": team_b,
                                "Bookmaker B": bookmaker_b,
                                "Odds B (Bookmaker B)": odds_b_a,
                                "Combined Odds": profit_b.toFixed(3)*100
                        });
                    }
                };
            };
        }
    }

        const surebetstable = {
            columns: [
                 {name: "Start Time", label: "Start Time" },
                { name: "Sport", label: "Sport" },
                { name: "Event", label: "Event" },
                { name: "Combined Odds", label: "Combined Odds"},
                { name: "Team A", label: "Team A" },
                { name: "Bookmaker A", label: "Bookmaker" },
                { name: "Odds A (Bookmaker A)", label: "Odds" },
                { name: "Team B", label: "Team B" },
                { name: "Bookmaker B", label: "Bookmaker" },
                { name: "Odds B (Bookmaker B)", label: "Odds" }
                //[ "Start Time", "Sport", "Event", "Combined Odds", "Team A", "Bookmaker", "Odds", "Team B", "Bookmaker", "Odds"]
            ],
            rows: [
                ...surebets
            ]
        };

        return { surebetstable };
    })
    .catch(error => {
        console.log(`Error for ${sportKey}:`, error.response.status);
        console.log(error.response.data);
        // Return an empty table for this sportKey
        return { surebetstable: { columns: [], rows: [] } };
    });
});

return Promise.all(requests);
}