import React, { useState } from "react";
import "../styles/invite.css";
import { Fade } from "@mui/material";
import classes from "../styles/popup.module.css";
import RSVPform from "../components/RSVPform";
import InviteHeader from "../components/InviteHeader";
import InviteFooter from "../components/InviteFooter";
import { useWindowSize } from "@react-hook/window-size";
import Confetti from 'react-confetti';

// Invite Content
const heading1 = "join us to celebrate";
const heading2 = "ADA'S FIRST BIRTHDAY";
const content = "Sunday 27th of August, 11:30am";
const notes1 = "Please RSVP by the 21st of August."
const notes2 = "Look for the colourful bunting and balloons on the day! Best parking is on Diggers Drive, located at the end of Park Road, Kalinga ";
let eventlocation = "Kalinga Park";

// Formulas and Concatenations
let eventlocationlink = "https://www.google.com/maps?q=" + eventlocation.replace(/\s+/g, '+');


function Invite() {
    const { width, height } = useWindowSize();
    const [popup, setPopup] = useState(false);
    function showPopup() {
      setPopup(true);
    }
    function closePopup(event) {
      // when mouse click at pop up screen
      // get if of current element
      let currentId = event.target.id;
      console.log(currentId);
      if (!currentId.includes("RSVP-form")) {
        setPopup(false);
      }
    }
    
    return (
      <div>
        <Confetti width={width} height={height} opacity="0.2" /> {/* https://www.npmjs.com/package/react-confetti */}
        <InviteHeader />
        <div className="invite">
          <div className="invite-h1"> {heading1}</div>
          <div className="invite-h2"> {heading2}</div>
          <div className="invite-content"> {content}</div>
          <div className="invite-content"> {eventlocation}</div>

          <div className="invite-links">
            <p className="invite-links-RSVP" onClick={showPopup}> RSVP </p>
              <Fade in={popup}>
              <div id="mask" className={classes.mask} onClick={closePopup}>
                <div id="RSVP-form" className={classes.login}>
                <RSVPform />
                </div>  
              </div>
              </Fade>
            <p className="invite-links-directions" onClick={() => (window.location.href = eventlocationlink)}>Need Directions?</p>
          </ div>

          <div className="invite-final-details"> {notes1}</div>
          <div className="invite-final-details"> {notes2}</div>
        </div>
        <InviteFooter />
      </div>
      
    );
}

export default Invite;