import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import findArbs from '../components/findArbs';
import { sports } from '../components/Arb_sports';
import ArbCalcsAppSingle from '../components/Arb_calcs_single';
import ArbCalcsAppDouble from '../components/Arb_calcs_double';
import PropTypes from 'prop-types';
import '../styles/arbs.css';

function Modal({ children, shown, close }) {
  if (!shown) return null;

  const handleBackdropClick = () => {
    close();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div className="modal-content" onClick={handleContentClick}>
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  shown: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};


function Arbs() {
  const [availableSports, setAvailableSports] = useState([]);
  const [modalShown, toggleModal] = React.useState(false);


  useEffect(() => {
    async function fetchSports() {
      try {
        const sportsData = await sports(); // Call the sports function
        setAvailableSports(sportsData); // Update the state with the fetched data
      } catch (error) {
        // Handle errors, e.g., show an error message to the user
      }
    }

    fetchSports(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures this effect runs once on component mount


  const [selectedSports, setSelectedSports] = useState([ 'americanfootball_ncaaf',
                                                          'americanfootball_nfl',
                                                          'aussierules_afl',
                                                          'basketball_nba',
                                                          'cricket_big_bash',
                                                          //'icehockey_nhl',
                                                          'mma_mixed_martial_arts',
                                                          'rugbyleague_nrl',
                                                        ]); // Default selected sport

  const [tableData, setTableData] = useState({}); 
  const [selectedRowData, setSelectedRowData] = useState([]);

  const handleCheckboxChange = (sport) => {
    const updatedSports = selectedSports.includes(sport)
      ? selectedSports.filter(s => s !== sport)
      : [...selectedSports, sport];

    setSelectedSports(updatedSports);
  };

  const handleButtonClick = () => {
    findArbs(selectedSports).then(data => {
      setTableData(data[0].surebetstable);
    });
  };

  // Odds1 should always be the bigger odds

  const options = {
    download: true,
    rowHover: true,
    filter: true,
    filterType: "dropdown",
    responsive: "simple",
    selectableRows:"single",
    selectableRowsHideCheckboxes:true,
    sort:true,
    sortOrder: {name: 'Combined Odds', direction: 'asc'},
    onRowClick: (rowData, rowState) => {
      setSelectedRowData(rowData);
      toggleModal(!modalShown)
    }
  };

  return (
    <div className='Arbs'>
      <header className="Arbs-header"> Arb Calculations</header>

      <div className="Arbs-checkboxes">
        {availableSports.map((sport, index) => (
          <div key={index} className="Arbs-checkbox-column">
            <label className="checkbox-label">
              <input
                type='checkbox'
                value={sport}
                checked={selectedSports.includes(sport)}
                onChange={() => handleCheckboxChange(sport)}
              />
              {sport}
            </label>
          </div>
        ))}
      </div>

      <div className="findArbsButton">
        <button onClick={handleButtonClick}>Find Arbs</button>
        </div>

        <Modal
        shown={modalShown}
        close={() => {
          toggleModal(false);
        }}
      >
        {ArbCalcsAppSingle(selectedRowData)}
        {ArbCalcsAppDouble(selectedRowData)}
      </Modal>

    <MUIDataTable
          data={tableData["rows"]}
          columns={tableData["columns"]}
          options={options}
        />

    </div>
  );
}

export default Arbs;
