import React from 'react';
import "../styles/InviteFooter.css";
import footerimage from "../assets/invite-footer.png";
 
const InviteFooter = () => (
    <footer className="invite-footer">
        <img src={footerimage} className="footer-image" alt="footer" />
    </footer>
);
 
export default InviteFooter;