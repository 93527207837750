import React, { useState } from 'react';
import "../styles/arb_calcs.css";

function UpdateProfitCalc(bet1, bet2, odds1, odds2){

  let profit1 = (bet1 * odds1) - bet1 - bet2;
  let profit2 = (bet2 * odds2) - bet1 - bet2;
  return( [profit1.toFixed(2), profit2.toFixed(2)]); 
}


function ArbCalcsAppSingle(selectedRowData) {
  let [time, sport, event, combinedodds, team1, bookmaker1, odds1, team2, bookmaker2, odds2] = selectedRowData

  const [bet1, setbet1] = useState(0);
  const [bet2, setbet2] = useState(0);
  const [profit1, setprofit1] = useState(0);
  const [profit2, setprofit2] = useState(0);

  const resetValues = () => {
    setbet1(0);
    setbet2(0);
  };


  const suggestValues  = () => {

    if (bet1 === 0) { 
      let newBet1 = (bet2 * odds2 / odds1).toFixed(2);              //here is the stuf we need to look at
      setbet1(newBet1);
      let [profit1, profit2] = UpdateProfitCalc(newBet1, bet2, odds1, odds2);
      setprofit1(profit1);
      setprofit2(profit2);  
      };

    if (bet2 === 0) {
      let newBet2 = (bet1 * odds1 / odds2).toFixed(2);              //here is the stuf we need to look at
      setbet2(newBet2);
      let [profit1, profit2] = UpdateProfitCalc(bet1, newBet2, odds1, odds2);
      setprofit1(profit1);
      setprofit2(profit2);  
    };

    };  


  const handleChange_side1 = (e) => {
    let newBet1; 
    newBet1 = parseFloat(e.target.value);
    setbet1(newBet1);
    let [profit1, profit2] = UpdateProfitCalc(newBet1, bet2, odds1, odds2);
    setprofit1(profit1);
    setprofit2(profit2);  
    };  

  const handleChange_side2 = (e) => {
    let newBet2; 
    newBet2 = parseFloat(e.target.value);
    setbet2(newBet2)
    let [profit1, profit2] = UpdateProfitCalc(bet1, newBet2, odds1, odds2);
    setprofit1(profit1);
    setprofit2(profit2);
  };  
  
  return (
    <div className='Arbs-Calcs'>
      <div className='Arbs-Calcs-heading'>Bet Results</div>

      {/* Bet - Side 1 */}
      <div className="Arbs-Calcs-Each-Side">
      <input 
        className="input-box"
        type="number"
        value={bet1}
        onChange={handleChange_side1}
        placeholder="0"
      />
      <p>bet on {team1}, ${odds1} at {bookmaker1}, returns profit of ${profit1}</p>
      </div>

      {/* Bet - Side 2 */}
      <div className="Arbs-Calcs-Each-Side">
      <input
        className="input-box"
        type="number"
        value={bet2}
        onChange={handleChange_side2}
        placeholder="0"
      />
      <p>bet on {team2}, ${odds2} at {bookmaker2}, returns profit of ${profit2}</p>
      </div>
      <div className='reset-button' onClick={suggestValues}>Suggest</div>
      <div className='reset-button' onClick={resetValues}>Reset</div>
      
    </div>
  );
}

export default ArbCalcsAppSingle;